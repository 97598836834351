import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ichwan/Documents/repos/new-personal-site/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Projects`}</h1>
    <p>{`This section is still under construction 🚧`}</p>
    {
      /* The following are notable projects that I've worked on throughout the years. Feel free to visit my GitHub account for additional projects. */
    }
    {
      /* <SourceList filter="projects">
       {posts => (
         <Flex
           sx={{
             flexWrap: 'wrap',
             ml: theme => `-${theme.space[2]}px`,
             mr: theme => `-${theme.space[2]}px`,
           }}
         >
           {posts
             .filter(edge => !edge.node.frontmatter.isPrivate)
             .reduce((routes, route) => {
               return route.node.frontmatter.pinned
                 ? [route, ...routes]
                 : [...routes, route]
             }, [])
             .map((edge, index) => {
               const {
                 featuredImageUrlSharp,
                 frontmatter: { title, featuredImage, tags, date, pinned, misc },
                 excerpt,
                 fields: { slug },
               } = edge.node
               return (
                 <Box
                   key={index}
                   sx={{
                     display: 'flex',
                     flex: '1 1 auto',
                     flexDirection: 'column',
                     mb: 3,
                     maxWidth: ['100%', '100%', '50%', '50%'],
                     width: ['100%', '100%', '50%', '50%'],
                   }}
                 >
                   <Link
                     href={slug}
                     sx={{
                       textDecoration: 'none',
                       display: 'flex',
                       flex: '1 1 auto',
                       flexDirection: 'column',
                       m: theme => `0px ${theme.space[2]}px`,
                       minHeight: '1px',
                     }}
                   >
                     <Card
                       sx={{
                         display: 'flex',
                         flex: '1 1 auto',
                         flexDirection: 'column',
                         minHeight: '1px',
                       }}
                     >
                       {featuredImage && featuredImage.childImageSharp && (
                         <Box sx={{ minHeight: '1px' }}>
                           <Img
                             fluid={featuredImage.childImageSharp.fluid}
                             alt={featuredImage.childImageSharp.fluid.originalName}
                           />
                         </Box>
                       )}
                     {featuredImageUrlSharp && featuredImageUrlSharp.childImageSharp && (
                         <Box sx={{ minHeight: '1px' }}>
                           <Img
                             fluid={featuredImageUrlSharp.childImageSharp.fluid}
                             alt={featuredImageUrlSharp.childImageSharp.fluid.originalName}
                           />
                         </Box>
                       )}
                       <Box
                         sx={{
                           display: 'flex',
                           flex: '1 1 auto',
                           flexDirection: 'column',
                           p: 3,
                         }}
                       >
                         <Heading
                           variant="styles.h4"
                           sx={{ color: pinned ? 'primary' : 'text' }}
                         >
                           {title}
                         </Heading>
                         <Text sx={{ mb: 1, color: 'muted' }}>
                           {misc}
                         </Text>
                         <Text sx={{ mb: 1, color: 'text' }}>{excerpt}</Text>
                       </Box>
                       <Box sx={{ p: 3 }}>
                         <Text>View Post</Text>
                       </Box>
                     </Card>
                   </Link>
                 </Box>
               )
             })}
         </Flex>
       )}
      </SourceList> */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      